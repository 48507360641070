import { Button } from '@lk/lk-design-system'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import React, { FC, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import {
  InsuranceAccidentQuotaModules,
  PatchInsuranceAccidentQuotaArgs,
  PatchInsuranceAccidentQuotaOptions,
} from '../../../../../services/lk-apis/types'
import AccessibleSelect from '../../../../AccessibleSelect/AccessibleSelect'
import LocalizedDatePicker from '../../../../LocalizedDatePicker/LocalizedDatePicker'

export interface ConfortProps {
  previousBirthDate: Date
  modules: InsuranceAccidentQuotaModules[]
  onSubmit: (values: PatchInsuranceAccidentQuotaArgs, option: PatchInsuranceAccidentQuotaOptions) => void
}

const InsuranceCalculatorSuperLK: FC<ConfortProps> = ({ previousBirthDate, modules, onSubmit }) => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)

  const { formatCurrency } = useI18nUtils()

  const deceaseOptions = [
    {
      label: t('widgetInsuranceCalculator.withoutDecease'),
      value: '0',
    },
    {
      label: t('widgetInsuranceCalculator.withDecease'),
      value: '1',
    },
  ]

  const [selectedModule, setSelectedModule] = useState()
  const [includeDecease, setIncludeDecease] = useState()

  const handleModuleChange = useCallback(
    (value) => {
      if (value !== selectedModule) {
        setSelectedModule(value)
      }
    },
    [selectedModule],
  )

  const handleDeceaseChange = useCallback(
    (value) => {
      if (value !== includeDecease) {
        setIncludeDecease(value)
      }
    },
    [includeDecease],
  )

  const handleSubmit = useCallback(() => {
    if (!selectedModule) return
    onSubmit(
      {
        moduleCode: parseInt(selectedModule, 10),
        includeDecease: parseInt(includeDecease, 10),
      },
      'GenerateBudgets',
    )
  }, [includeDecease, onSubmit, selectedModule])

  const moduleOptions = modules.map((module) => ({
    label: formatCurrency(module.moduleImport.amount, module.moduleImport.currency),
    value: module.moduleCode.toString(),
  }))

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <LocalizedDatePicker
            language={language}
            label={t('widgetInsuranceCalculator.age')}
            onChange={() => undefined}
            selected={previousBirthDate}
            disabled
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <AccessibleSelect
            name="modules"
            label={t('widgetInsuranceCalculator.quantity')}
            options={moduleOptions}
            initialValue=""
            onItemSelected={handleModuleChange}
            placeholder={t('widgetInsuranceCalculator.selectQuantity')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <AccessibleSelect
            name="deceases"
            label={t('widgetInsuranceCalculator.deceases')}
            options={deceaseOptions}
            initialValue=""
            onItemSelected={handleDeceaseChange}
            placeholder={t('widgetInsuranceCalculator.selectDecease')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 lk-widget-insurance-calculator__actions">
          <Button buttonRole="primary" label={t('calculators.calculateButton')} type="button" onClick={handleSubmit} />
        </div>
      </div>
    </>
  )
}

export default InsuranceCalculatorSuperLK
