import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useI18nUtils from '../../../../../hooks/useI18nUtils'
import { InsuranceAccidentQuotaModules, PatchInsuranceAccidentQuotaBudget } from '../../../../../services/lk-apis/types'
import InsuranceCalculatorOfferBox from './InsuranceCalculatorOfferBox'

interface InsuranceCalculatorOfferBoxProps {
  currentBudget: PatchInsuranceAccidentQuotaBudget
  onChangeBudget: (newSelectedBudget: InsuranceAccidentQuotaModules) => void
}

const InsuranceCalculatorOffers: FC<InsuranceCalculatorOfferBoxProps> = ({ currentBudget, onChangeBudget }) => {
  const { formatCurrency } = useI18nUtils()
  const { t } = useTranslation()
  const [currentBudgetSelected, setCurrentBudgetSelected] = useState(true)
  const [superiorBudgetSelected, setSuperiorBudgetSelected] = useState(false)

  const handleCurrentBudgetClick = () => {
    setCurrentBudgetSelected(true)
    setSuperiorBudgetSelected(false)
    onChangeBudget(currentBudget.budgetedModule)
  }

  const handleSuperiorBudgetClick = () => {
    setSuperiorBudgetSelected(true)
    setCurrentBudgetSelected(false)
    onChangeBudget(currentBudget.superiorBudgetedModule)
  }

  return (
    <div className="lk-calculator__aside">
      <div className="col-lg-4 col-md-12 lk-widget-insurance-calculator__box">
        <InsuranceCalculatorOfferBox selected={currentBudgetSelected} onClick={handleCurrentBudgetClick}>
          <div className="lk-calculator__highlighted-text strong m-0">{t('widgetInsuranceCalculator.offerTitle')}</div>
          <div className="lk-calculator__result">
            <p className="lk-calculator__quantity">
              <span className="strong" data-testid="lk-calculator-quantity">
                {formatCurrency(currentBudget?.budgetedModule.anualQuota.amount)}
                {t('calculators.currencyPerYear')}
              </span>
            </p>
          </div>
          <div className="lk-calculator__highlighted-text strong m-0">
            {t('widgetInsuranceCalculator.capitalOf')}{' '}
            <span className="strong" data-testid="lk-calculator-quantity">
              {formatCurrency(currentBudget?.budgetedModule.moduleImport.amount)}
            </span>
          </div>

          <div className="lk-calculator__key-points">
            <div className="includes strong text-uppercase">{t('widgetInsuranceCalculator.includes')}</div>
            <ul>
              {currentBudget?.budgetedModule.cobertures?.map((property, index) => {
                const key = `insurance-calculator_${index}`
                return <li key={key}>{property.description}</li>
              })}
            </ul>
          </div>
        </InsuranceCalculatorOfferBox>
      </div>
      {currentBudget?.superiorBudgetedModule && (
        <div className="col-lg-4 col-md-12 lk-widget-insurance-calculator__box">
          <InsuranceCalculatorOfferBox selected={superiorBudgetSelected} onClick={handleSuperiorBudgetClick}>
            <div className="strong">
              <div className="lk-calculator__highlighted-text text-uppercase lk-widget-insurance-calculator-choice__header-title">
                {t('widgetInsuranceCalculator.improveYourInsurance')}
              </div>
              {t('widgetInsuranceCalculator.resultTitle')}
              <div className="lk-calculator__result">
                <p className="lk-calculator__quantity">
                  <span className="" data-testid="lk-calculator-quantity">
                    {formatCurrency(currentBudget?.budgetedModule.anualQuota.amount)}
                    {t('calculators.currencyPerYear')}
                  </span>
                </p>
              </div>
            </div>
            <div className="lk-calculator__highlighted-text strong m-0">
              {t('widgetInsuranceCalculator.superiorBudget')}
              <span className="strong" data-testid="lk-calculator-quantity">
                {formatCurrency(
                  currentBudget.superiorBudgetedModule.moduleImport.amount,
                  currentBudget.superiorBudgetedModule.moduleImport.currency,
                )}
              </span>
            </div>
            <div className="lk-calculator__key-points">
              <div className="includes strong text-uppercase">{t('widgetInsuranceCalculator.includes')}</div>
              <ul>
                {currentBudget?.superiorBudgetedModule.cobertures?.map((property, index) => {
                  const key = `insurance-calculator_${index}`
                  return <li key={key}>{property.description}</li>
                })}
              </ul>
            </div>
          </InsuranceCalculatorOfferBox>
        </div>
      )}
    </div>
  )
}

export default InsuranceCalculatorOffers
